import {
  generateAction,
  generateDeleteAction,
  generateGetAction,
  generatePostAction,
  generatePutAction,
  // generatePutAction,
} from '@services/ActionDispatcher';

import {
  inviteStudioAction,
  getCreatorsAction,
  getStudiosAction,
  getCreatorAction,
  editCreatorAction,
  getStudioAction,
  editStudioAction,
  updateStudioInviteAction,
  deleteStudioInviteAction,
  getStudioInviteAction,
  enableAccountAction,
  disableAccountAction,
  getAccountReportsAction,
  editReportAccountStateAction,
  getPostReportsAction,
  getAccountReportDetailsAction,
  getPostReportDetailsAction,
  editReportedAccountStatusAction,
  editReportedPostStatusAction,
  editReportPostStateAction,
  saveReportedUserData,
  clearUserStateAction,
  getPostReportCookiesAction,
} from './UserActionConfig';

const editReportAccountState = (data: any) => generateAction(editReportAccountStateAction, data);
const editReportPostState = (data: any) => generateAction(editReportPostStateAction, data);
const saveReportedUserDataState = (data: any) => generateAction(saveReportedUserData, data);
const clearUserState = () => generateAction(clearUserStateAction);

const inviteStudioRequest = (data: object) => generatePostAction(
  inviteStudioAction,
  'studio/invite',
  { ...data },
);

const getCreatorsRequest = (data: object) => generateGetAction(
  getCreatorsAction,
  'accounts/creators',
  { ...data },
);

const getCreatorRequest = (id: string, data: object) => generateGetAction(
  getCreatorAction,
  `accounts/creator/${id}`,
  { ...data },
);

const editCreatorRequest = (id: string, data: object) => generatePutAction(
  editCreatorAction,
  `accounts/creator/${id}`,
  { ...data },
);

const getStudiosRequest = (data: object) => generateGetAction(
  getStudiosAction,
  'accounts/studios',
  { ...data },
);

const getStudioRequest = (id: string, data: object) => generateGetAction(
  getStudioAction,
  `accounts/studio/${id}`,
  { ...data },
);

const editStudioRequest = (id: string, data: object) => generatePutAction(
  editStudioAction,
  `accounts/studio/${id}`,
  { ...data },
);

// const editStatusRequest = (id: string) => generatePutAction(
//   editStatusAction,
//   `accounts/status/${id}/edit`,
// );

const getStudioInviteRequest = (id: string) => generateGetAction(
  getStudioInviteAction,
  `accounts/invite/${id}`,
);

const updateStudioInviteRequest = (id: string, data: object) => generatePutAction(
  updateStudioInviteAction,
  `accounts/invite/${id}`,
  { ...data },
);

const deleteStudioInviteRequest = (id: string) => generateDeleteAction(
  deleteStudioInviteAction,
  `accounts/invite/${id}`,
);

const disableAccountRequest = (id: string) => generatePutAction(
  disableAccountAction,
  `accounts/${id}/disable`,
);

const enableAccountRequest = (id: string) => generatePutAction(
  enableAccountAction,
  `accounts/${id}/enable`,
);

const getAccountReportsRequest = (data: object) => generateGetAction(
  getAccountReportsAction,
  'moderate/client',
  { ...data },
);

const getAccountReportDetailsRequest = (id: string, data: object) => generateGetAction(
  getAccountReportDetailsAction,
  `moderate/client/${id}`,
  { ...data },
);

const editReportedAccountStatusRequest = (id: string, data: object) => generatePutAction(
  editReportedAccountStatusAction,
  `moderate/client/${id}`,
  { ...data },
);

const getPostReportsRequest = (data: object) => generateGetAction(
  getPostReportsAction,
  'moderate/post',
  { ...data },
);

const getPostReportDetailsRequest = (id: string, data: object) => generateGetAction(
  getPostReportDetailsAction,
  `moderate/post/${id}`,
  { ...data },
);

const editReportedPostStatusRequest = (id: string, data: object) => generatePutAction(
  editReportedPostStatusAction,
  `moderate/post/${id}`,
  { ...data },
);

const getPostReportCookiesRequest = (id: string, data: object) => generateGetAction(
  getPostReportCookiesAction,
  `moderate/post/${id}/cookies`,
  { ...data, withCredentials: true },
);

export {

  // async (request) dispatch
  inviteStudioRequest,
  getCreatorsRequest,
  getCreatorRequest,
  editCreatorRequest,
  getStudiosRequest,
  getStudioRequest,
  editStudioRequest,
  getStudioInviteRequest,
  updateStudioInviteRequest,
  deleteStudioInviteRequest,
  disableAccountRequest,
  enableAccountRequest,
  getAccountReportsRequest,
  getAccountReportDetailsRequest,
  editReportedAccountStatusRequest,
  editReportAccountState,
  getPostReportsRequest,
  getPostReportDetailsRequest,
  editReportedPostStatusRequest,
  editReportPostState,
  saveReportedUserDataState,
  clearUserState,
  getPostReportCookiesRequest,
};
