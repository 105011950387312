import { AnyAction } from 'redux';
import { handleActions, Action } from 'redux-actions';
import LocaleService from '@services/LocaleService';

import appConfig from '@config/app';

import {
  setAppTitleAction,
  setAppDescriptionAction,
} from './AppActionConfig';

const generalTranslations = LocaleService.getTranslations('general');
const { app } = generalTranslations as any;

const { projectName } = appConfig;

interface AppState {
  appTitle?: string | any;
  appDescription?: string | any;
}

const defaultState: AppState = {
  appTitle: projectName,
  appDescription: LocaleService.parseTranslation(app.description, {
    ':name': projectName,
  }),
};

const appReducer = handleActions<AppState, AnyAction>(
  {

    [setAppTitleAction.toString()]: (state: AppState, { payload }: Action<AnyAction>) => ({
      ...state,
      appTitle: payload,
    }),

    [setAppDescriptionAction.toString()]: (state: AppState, { payload }: Action<AnyAction>) => ({
      ...state,
      appDescription: payload,
    }),

  },
  defaultState,
);

export default appReducer;
