import React, { FC } from 'react';
import PropTypes from 'prop-types';
import './style.css';

interface LoadingBlockProps {
  show?: boolean;
}

const LoadingBlock: FC<LoadingBlockProps> = ({ show }) => {
  if (show) {
    return (
      <div className="loading-backdrop">
        <div className="loading" />
      </div>
    );
  }

  return null;
};

LoadingBlock.defaultProps = {
  show: false,
};

LoadingBlock.propTypes = {
  show: PropTypes.bool,
};

export default LoadingBlock;
