/* eslint-disable max-len */
import { handleActions, Action } from 'redux-actions';
import { GetUserProperties, IPaginatedData } from '@app/types/IPaginatedData';
import { disableAdminAction, enableAdminAction, getAdminsAction } from './AdminActionConfig';

type PaginatedAdmins = IPaginatedData<GetUserProperties<'admins'>>;

interface AdminState {
  admins: PaginatedAdmins | null;
}

const defaultState: AdminState = {
  admins: null,
};

const adminReducer = handleActions<AdminState, any>(
  {
    [getAdminsAction.toString()]: (
      state: AdminState,
      { payload }: Action<PaginatedAdmins>,
    ) => ({
      ...state,
      admins: payload,
    }),
    [disableAdminAction.toString()]: (
      state: AdminState,
      { payload }: Action<{ id: string }>,
    ) => {
      const { id } = payload;

      return {
        ...state,
        admins: state.admins ? {
          ...state.admins,
          data: state.admins?.data.map((item) => (item.id === id ? { ...item, status: 'disabled' } : item)),
        } : null,
      };
    },
    [enableAdminAction.toString()]: (
      state: AdminState,
      { payload }: Action<{ id: string }>,
    ) => {
      const { id } = payload;

      return {
        ...state,
        admins: state.admins ? {
          ...state.admins,
          data: state.admins?.data.map((item) => (item.id === id ? { ...item, status: 'active' } : item)),
        } : null,
      };
    },
  },
  defaultState,
);

export default adminReducer;
