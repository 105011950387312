/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';
// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const setArtistAction = createAction('USER_SET_ARTIST');

export const inviteStudioAction = createAction('USER_INVITE_STUDIO');
export const getCreatorsAction = createAction('USER_GET_CREATORS');
export const getCreatorAction = createAction('USER_GET_CREATOR');
export const editCreatorAction = createAction('USER_EDIT_CREATOR');
// studios
export const getStudiosAction = createAction('USER_GET_STUDIOS');
export const getStudioAction = createAction('USER_GET_STUDIO');
export const editStudioAction = createAction('USER_EDIT_STUDIO');

// studio invites
export const getStudioInviteAction = createAction('USER_GET_STUDIO_INVITE');
export const updateStudioInviteAction = createAction('USER_UPDATE_STUDIO_INVITE');
export const deleteStudioInviteAction = createAction('USER_DELETE_STUDIO_INVITE');

// Account
export const disableAccountAction = createAction('USER_DISABLE_ACCOUNT');
export const enableAccountAction = createAction('USER_ENABLE_ACCOUNT');

// Reports
export const saveReportedUserData = createAction('USER_SAVE_REPORTED_USER_DATA');
export const getAccountReportsAction = createAction('USER_GET_ACCOUNT_REPORTS');
export const getAccountReportDetailsAction = createAction('USER_GET_ACCOUNT_REPORT_DETAILS');
export const editReportedAccountStatusAction = createAction('USER_EDIT_REPORTED_ACCOUNT_STATUS');
export const editReportAccountStateAction = createAction('USER_EDIT_REPORT_ACCOUNT_STATE');

export const getPostReportsAction = createAction('USER_GET_POST_REPORTS');
export const getPostReportDetailsAction = createAction('USER_GET_POST_REPORT_DETAILS');
export const editReportedPostStatusAction = createAction('USER_EDIT_REPORTED_POST_STATUS');
export const editReportPostStateAction = createAction('USER_EDIT_REPORT_POST_STATE');
export const getPostReportCookiesAction = createAction('USER_GET_POST_REPORT_COOKIES');

// Clear User State
export const clearUserStateAction = createAction('USER_CLEAR_STATE');
