import { AnyAction } from 'redux';
import { handleActions, Action } from 'redux-actions';

import { editSystemSettingsAction, getSystemSettingsAction } from './SettingsActionConfig';

interface SettingsState {
  settings: any[],
}

const defaultState: SettingsState = {
  settings: [],
};

const settingsReducer = handleActions<SettingsState, AnyAction>(
  {
    [getSystemSettingsAction.toString()]: (
      state: SettingsState,
      { payload }: Action<AnyAction>,
    ) => ({
      ...state,
      settings: [
        ...payload.data,
      ],

    }),

    [editSystemSettingsAction.toString()]: (
      state: SettingsState,
      { payload }: Action<AnyAction>,
    ) => {
      const { settings } = state;
      const updatedSettings = settings.map(setting => {
        if (setting.code in payload) {
          return { ...setting, value: payload[setting.code] };
        }
        return setting;
      });

      return ({
        ...state,
        settings: [
          ...updatedSettings,
        ],
      });
    },

  },
  defaultState,
);

export default settingsReducer;
