import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

type Props = {
  // description?: string;
  children: JSX.Element | JSX.Element[];
  // title?: string;
};

const AppContainer = ({ children }: Props) => {
  const appTitle = useSelector((state: any) => state.App.appTitle);
  const appDescription = useSelector((state: any) => state.App.appDescription);

  return (
    <div>
      <Helmet>
        <title>{appTitle}</title>
        <meta name="description" content={appDescription} />
      </Helmet>
      {children}
    </div>
  );
};

export default AppContainer;
