/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';
// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const loginAction = createAction('APP_AUTH_LOGIN');
export const logoutAction = createAction('APP_AUTH_LOGOUT');
export const clearUserTokenAction = createAction('APP_AUTH_USER_CLEAR_TOKEN');
export const getUserDetailsAction = createAction('APP_AUTH_GET_USER_DETAILS');
export const updateInformationAction = createAction('APP_AUTH_UPDATE_INFORMATION');
export const updateUserDetailsAction = createAction('APP_AUTH_UPDATE_USER_DETAILS');

export const sendCodeAction = createAction('APP_AUTH_SEND_CODE');
export const verifyCodeAction = createAction('APP_AUTH_VERIFY_CODE');
export const changePasswordAction = createAction('APP_AUTH_CHANGE_PASSWORD');
export const changeInitialPasswordAction = createAction('APP_AUTH_CHANGE_INITIAL_PASSWORD');

export const setEmailAction = createAction('APP_SET_EMAIL');
export const createResendCodeDurationAction = createAction('APP_CREATE_RESEND_CODE_DURATION');
export const updateResendCodeDurationAction = createAction('APP_UPDATE_RESEND_CODE_DURATION');
