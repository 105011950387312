/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';
// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY

export const inviteAdminAction = createAction('ADMIN_INVITE');
export const getAdminsAction = createAction('ADMIN_GET_ADMINS');
export const getAdminAction = createAction('ADMIN_GET_ADMIN');
export const editAdminAction = createAction('ADMIN_EDIT_ADMIN');
export const disableAdminAction = createAction('ADMIN_DISABLE_ADMIN');
export const enableAdminAction = createAction('ADMIN_ENABLE_ADMIN');
