import {
  LogoLight, LogoDark, LogoLightRTL, LogoDarkRTL,
} from '@app/shared/icons';

export default {
  // prevent redirection if 401 is received
  whitelistUrl: [
    '/login',
  ],
  appVersion: process.env.REACT_APP_VERSION || '1.0.0',
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  projectName: 'Admin Portal',
  logo: {
    Light: LogoLight,
    LightRtl: LogoLightRTL,
    Dark: LogoDark,
    DarkRtl: LogoDarkRTL,
  },
};
