import axios from 'axios';
import { Path } from 'path-parser';

import appConfig from '@config/app';

import { handleError } from '@services/ErrorHandler';
import { clearUserTokenAction } from '../reducers/auth/AuthActionConfig';
import { store } from './store';

const { whitelistUrl } = appConfig;

const configuredAxios = { ...axios };
// headers
// configuredAxios.defaults.headers = {
//   common: {
//     'Content-Type': 'application/json',
//     Accept: 'application/json',
//     'X-Requested-With': 'XMLHttpRequest',
//   },
// };
configuredAxios.defaults.headers.common['Content-Type'] = 'application/json';
configuredAxios.defaults.headers.common.Accept = 'application/json';
configuredAxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

configuredAxios.interceptors.request.use(
  async (config) => {
    const c = config;
    const state = store.getState();
    const { accessToken } = state.Auth;

    if (accessToken) {
      c.headers.Authorization = `Bearer ${accessToken}`;
    }
    return c;
  },
  error => Promise.reject(error),
);

function interceptSuccess(response: any) {
  // success interception logic here
  return response;
}

function isWhiteListed(url: any) {
  const pathname = url.split('/api')[1];
  return whitelistUrl.some(path => new Path(path).test(pathname));
}

function interceptError(err: any) {
  const { response } = err;
  // connection refuse
  if (!response) {
    // eslint-disable-next-line no-console
    console.log('response', response);
  } else if (response.status === 401) { // unauthorized
    if (window.location.pathname.startsWith('/admin')) {
      handleError(err);
    }

    // remove the saved token and user data then proceed
    // on redirecting to root page (or login)
    // refresh the page and redirect to root page
    if (!isWhiteListed(response.config.url)) {
      store.dispatch(clearUserTokenAction());
      // window.location.href = '/auth/login';
    }
  }

  return Promise.reject(err);
}

// interceptors
configuredAxios.interceptors.response.use(interceptSuccess, interceptError);

export default configuredAxios;
