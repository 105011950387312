/* eslint-disable max-len */
import { AnyAction } from 'redux';
import { handleActions, Action } from 'redux-actions';

import moment from 'moment';
import {
  loginAction,
  logoutAction,
  clearUserTokenAction,
  getUserDetailsAction,
  updateInformationAction,
  setEmailAction,
  changeInitialPasswordAction,
  updateUserDetailsAction,
  updateResendCodeDurationAction,
  createResendCodeDurationAction,
  sendCodeAction,
  changePasswordAction,
} from './AuthActionConfig';

interface AuthState {
  accessToken: string | null;
  user: object;
  sendCodeEmail: string,
  passwordUpdated: boolean
  forgotPasswordUpdated: boolean
  verification: {
    forgotPassword: {
      resendTimer: number;
      resendTimerStart: number
      resendTimerEnd: number
    }
  }
  resendCodeInSeconds: number
}

const defaultState: AuthState = {
  accessToken: null,
  user: {},
  sendCodeEmail: '',
  passwordUpdated: false,
  forgotPasswordUpdated: false,
  verification: {
    forgotPassword: {
      resendTimer: 0,
      resendTimerStart: 0,
      resendTimerEnd: 0,
    },
  },
  resendCodeInSeconds: 0,
};

const authReducer = handleActions<AuthState, AnyAction>(
  {
    [getUserDetailsAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => ({
      ...state,
      ...payload,
    }),

    [loginAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => {
      const { access_token: accessToken, user } = payload;
      return {
        accessToken,
        passwordUpdated: user.is_password_changed,
        ...user,
      };
    },

    [logoutAction.toString()]: () => defaultState,

    [clearUserTokenAction.toString()]: () => defaultState,

    [updateInformationAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => ({
      ...state,
      ...payload,
      passwordUpdated: payload.is_password_changed,
    }),

    [sendCodeAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => ({
      ...state,
      resendCodeInSeconds: Number(payload.resendCodeInSeconds ?? payload.codeValidityInSeconds),
    }),

    [changePasswordAction.toString()]: (state: AuthState) => ({
      ...state,
      forgotPasswordUpdated: true,
    }),

    [sendCodeAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => ({
      ...state,
      resendCodeInSeconds: Number(payload.resendCodeInSeconds),
    }),

    [setEmailAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => {
      const { email } = payload;

      return {
        ...state,
        sendCodeEmail: email,
      };
    },

    [changeInitialPasswordAction.toString()]: (state: AuthState) => ({
      ...state,
      passwordUpdated: true,
    }),

    [updateUserDetailsAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => ({
      ...state,
      ...payload,
    }),

    [createResendCodeDurationAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => {
      const { type } = payload;
      const key = type as keyof AuthState['verification'];

      const verificationType = state.verification[type as keyof AuthState['verification']];

      return {
        ...state,
        verification: {
          ...state.verification,
          [key]: {
            ...verificationType,
            resendTimer: state.resendCodeInSeconds,
            resendTimerEnd: moment().unix() + state.resendCodeInSeconds,
            resendTimerStart: moment().unix(),
          },
        },
      };
    },

    [updateResendCodeDurationAction.toString()]: (state: AuthState, { payload }: Action<AnyAction>) => {
      const { type } = payload;
      const key = type as keyof AuthState['verification'];

      const verificationType = state.verification[type as keyof AuthState['verification']];
      if (verificationType.resendTimer <= 0) {
        return state;
      }

      const currentTime = moment().unix();
      const elapsed = currentTime - verificationType.resendTimerStart;

      return {
        ...state,
        verification: {
          ...state.verification,
          [key]: {
            ...verificationType,
            resendTimer: Math.max(verificationType.resendTimer - elapsed, 0),
            resendTimerStart: currentTime,
          },
        },
      };
    },
  },
  defaultState,
);

export default authReducer;
