import { I18n } from 'i18n-js';
import moment from 'moment';

import availableLocales from '@locale/available-locales';

const i18n = new I18n();

i18n.translations = {
  ...availableLocales,
};

const fallbackLocale = 'en';

// eslint-disable-next-line no-undef
const getDeviceLanguage = () => navigator.language;

// Use navigator.language to get the user's language from the browser
// eslint-disable-next-line no-undef
i18n.locale = navigator.language;
// Set the i18n fallbackLocale
i18n.enableFallback = true;
i18n.defaultLocale = fallbackLocale;

const setLanguage = (language) => {
  const languageCode = language.substring(0, 2);
  const momentLocale = moment.locales().find(e => e.startsWith(languageCode));
  moment.locale(momentLocale || fallbackLocale);
  i18n.locale(language);
};

const parseTranslation = (text, data) => {
  if (!data) return text;

  let parsedText = text;

  Object.entries(data).forEach(([ key, value ]) => {
    parsedText = parsedText.replace(key, value);
  });

  return parsedText;
};

const getTranslations = (file) => i18n.t(file);

const parseTranslationElement = (text, data) => {
  if (!data) return text;

  // create a regexp first
  const splitRegExString = [];
  const expectedStrings = {};
  Object.entries(data).forEach(([ key, value ]) => {
    // eslint-disable-next-line no-useless-escape
    const regExString = `<${key}>(.*?)<\/${key}>`;
    // push the split regex
    splitRegExString.push(regExString);

    // then get the child text to convert it later
    const regEx = new RegExp(regExString, 'g');
    const foundText = text.match(regEx) || [];
    // if there is 2 text with same element loop through them
    foundText.map((foundTextValue) => {
      // child text inside the element tag
      let childText = foundTextValue.replace(`<${key}>`, '');
      childText = childText.replace(`</${key}>`, '');

      // after getting the child text store the key as temp in the arr
      // and the callback provided
      expectedStrings[childText] = value;

      return foundTextValue;
    });
  });

  const splitRegex = new RegExp(splitRegExString.join('|'), 'g');
  const splitText = text.split(splitRegex);

  return splitText.map((convertedText) => {
    if (expectedStrings[convertedText]) {
      return expectedStrings[convertedText](convertedText);
    }

    return convertedText;
  });
};

export default {
  getDeviceLanguage,
  setLanguage,
  getTranslations,
  parseTranslation,
  parseTranslationElement,
};
