/* eslint-disable no-undef */
import moment from 'moment';
import numeral from 'numeral';
import LocaleService from '@services/LocaleService';

// eslint-disable-next-line
import packageJson from '@root/package.json';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import heic2any from 'heic2any';

const packageName = packageJson.name;

const currencyFormat = (value, format = '0,0.00', currency = '₱', pad = true) => `${currency}${pad ? ' ' : ''}${numeral(value).format(format)}`;

const shortDateFormat = date => moment(date).format('L');

const serverDateFormat = date => moment(date).format('YYYY-MM-DD');

// Replace underscore with spaces and capitalize words
const humanize = (str) => {
  const removedUnderscore = str.split('_');
  const capitalizedWords = removedUnderscore.map(
    word => word.slice(0, 1).toUpperCase() + word.slice(1),
  );
  const joinedWords = capitalizedWords.join(' ');
  // Capitalize words that has slash symbol
  const removeSlashed = joinedWords.split('/');
  const mappedSlashed = removeSlashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  // Capitalize words that has dash symbol
  const joinedMappedWords = mappedSlashed.join('/');
  const removeDashed = joinedMappedWords.split('-');
  const mappedDashed = removeDashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  return mappedDashed.join('-');
};

const time24HourFormat = date => moment(date).format('HH:mm');

const dateFormat = date => moment(date).format('YYYY-MM-DD');

const timestampToMonthDay = date => moment(date).format('MMM. DD');

const timestampToDate = (date, showYear = false) => {
  const parsedDate = moment(date);
  if (showYear) return parsedDate.format('MMM. D, YYYY');

  const now = moment();
  return now.isSame(date, 'year') ? parsedDate.format('MMM. D') : parsedDate.format('MMM. D, YYYY');
};

const timestampToDayOfWeek = date => moment(date).format('dddd');

const timestampToDateTime = date => moment(date).format('MMMM D, YYYY h:mm a');

const timestampToDateFull = data => moment(data).format('MMMM D, YYYY');

const timeBetween = (date) => {
  const now = moment();
  const then = moment.utc(date).local();
  const isFuture = then.isAfter(now);
  const duration = moment.duration(then.diff(now));

  const timeComponents = [
    { value: Math.abs(duration.days()), unit: 'day' },
    { value: Math.abs(duration.hours()), unit: 'hour' },
    { value: Math.abs(duration.minutes()), unit: 'minute' },
  ];

  const timePart = timeComponents.find(({ value }) => value > 0) || { value: 0, unit: 'minute' };

  return `${timePart.value} ${timePart.unit}${timePart.value !== 1 ? 's' : ''} ${isFuture ? 'from now' : 'ago'}`;
};

const normalizeMobile = (mobile) => {
  let normalizedMobile = mobile;
  if (mobile.startsWith('0')) {
    normalizedMobile = normalizedMobile.replace('0', '');
  } else if (mobile.startsWith('63')) {
    normalizedMobile = normalizedMobile.replace('63', '');
  } else if (mobile.startsWith('+63')) {
    normalizedMobile = normalizedMobile.replace('+63', '');
  }
  return normalizedMobile;
};

const getCurrentVersion = () => localStorage.getItem(`${packageName}-version`);

const isLatestVersion = currentVersion => currentVersion === getCurrentVersion();

const setLatestVersion = version => localStorage.setItem(`${packageName}-version`, version);

const validate = async (schema, formData) => {
  const errObj = {};

  await schema.validate(formData, { abortEarly: false }).catch(err => {
    err.inner.forEach((e) => {
      // remove the quotation marks of the array index
      const initialPath = e.path.replace(/["']/g, '');
      // format the array of index validation with "validation.index"
      const finalPath = initialPath.replace(/\[([^\]]+)\]/g, '.$1');
      errObj[finalPath] = LocaleService.getTranslations(e.message);
    });
  });

  return errObj;
};

const formatDuration = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  const minutes = Math.floor(duration.asMinutes()); // Total minutes
  const remainingSeconds = duration.seconds(); // Remaining seconds

  return `${minutes > 0 ? `${minutes} min` : ''}${minutes > 0 && remainingSeconds > 0 ? ' ' : ''}${remainingSeconds > 0 ? `${remainingSeconds} sec` : ''}`.trim();
};

const isUuid = (uuid) => {
  // Regular expression to match UUID format
  const uuidPattern = /^[a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}$/i;
  return uuidPattern.test(uuid);
};

const stringInitials = (n) => {
  const [ first, ...rest ] = n.trim().split(' ');
  const last = rest.length ? rest[rest.length - 1] : '';
  return (first[0] + (last[0] || '')).toUpperCase();
};

const useUrlParams = (initialParams) => {
  const history = useHistory();
  const location = useLocation();

  const [ params, setParams ] = useState(initialParams);

  const updateUrlParams = (newParams) => {
    setParams((prevParams) => {
      const updatedParams = { ...prevParams, ...newParams };

      // Filter out empty, null, or undefined params
      const filteredParams = Object.entries(updatedParams).reduce(
        (acc, [ key, value ]) => {
          if (value !== '' && value !== null && value !== undefined) {
            acc[key] = String(value);
          }
          return acc;
        },
        {},
      );

      // Update the search parameters in the URL
      const updatedSearchParams = new URLSearchParams(filteredParams).toString();

      history.replace({
        pathname: location.pathname,
        search: updatedSearchParams,
      });

      return updatedParams;
    });
  };

  return { params, updateUrlParams };
};

const convertHEICToJpeg = async (img) => {
  const response = await fetch(img);
  const blob = await response.blob();
  const convertedBlob = await heic2any({ blob, toType: 'image/jpeg' });
  const imageUrlConverted = URL.createObjectURL(convertedBlob);
  return imageUrlConverted;
};

export {
  currencyFormat,
  shortDateFormat,
  humanize,
  dateFormat,
  time24HourFormat,
  timestampToMonthDay,
  timestampToDate,
  timestampToDayOfWeek,
  timestampToDateTime,
  timestampToDateFull,
  normalizeMobile,
  serverDateFormat,
  getCurrentVersion,
  isLatestVersion,
  setLatestVersion,
  validate,
  formatDuration,
  timeBetween,
  isUuid,
  stringInitials,
  useUrlParams,
  convertHEICToJpeg,
};
