/* eslint-disable import/prefer-default-export */

import LocaleService from '@services/LocaleService';

import { NotificationManager } from '@shared/utils/Notification';

const translations = LocaleService.getTranslations('general.error');

const errorResponse = {
  401: translations.unauthorized_access,
  429: translations.throttle,
  422: translations.validation_error,
  500: translations.internal_server,
};

const parseErrorResponse = (response) => {
  // default message
  let message = translations.default;
  // means no internet or couldn't establish connection
  if (!response) {
    message = translations.establish_connection;
    // } else if (response.status === 422) {
    //   // validation error
    //   // temp: get the first error
    //   const { errors } = response.data;
    //   console.log('check422Errors 1', errors);
    //   // eslint-disable-next-line prefer-destructuring
    //   message = {
    //     message: Object.values(errors)[0][0],
    //     title: translations.validation_error.title,
    //   };
    // console.log('check422Errors 2', message);
  } else if (response.status === 400) {
    // eslint-disable-next-line prefer-destructuring
    message = {
      message: response.data.message,
      title: translations.invalid_request.title,
    };
  } else if (errorResponse[response.status]) {
    // if status code is defined in error response object
    message = errorResponse[response.status];
  }

  return message;
};

export const handleError = (err) => {
  const { response } = err;
  const { title, message } = parseErrorResponse(response);
  // implement display of error here
  return NotificationManager.error(message, title);
};

// eslint-disable-next-line no-console
export const logError = error => console.log('error in request', error);
