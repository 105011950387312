import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingBlock from '@shared/utils/LoadingBlock';

import CacheBuster from '@shared/utils/CacheBuster';

import { store, persistor } from './config/store';

import './AppStyle.css';
import AppContainer from './AppContainer';

const LazyAppRouter = lazy(() => import('@app/AppRouter'));

const App = () => (
  <Provider store={store}>
    <CacheBuster>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<LoadingBlock show />}>
          <AppContainer>
            <LazyAppRouter />
          </AppContainer>
        </Suspense>
      </PersistGate>
    </CacheBuster>
  </Provider>
);

export default App;
