import auth from './auth.json';
import general from './general.json';
import users from './users.json';
import settings from './settings.json';
import moderate from './moderate.json';
import admin from './admin.json';
import dashboard from './dashboard.json';

export default {
  auth,
  general,
  users,
  settings,
  moderate,
  admin,
  dashboard,
};
