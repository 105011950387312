import {
  createStore, applyMiddleware, Store,
} from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import createDebounce from 'redux-debounced';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import reducer from '@reducers/main';

// const config = {
//   simple: 300,
// };

const debouncer = createDebounce();

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  // whitelist should be access token and user only
  // whitelist: [ 'Auth' ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store: Store<any, any> = createStore(
  persistedReducer,
  applyMiddleware(thunk as ThunkMiddleware<any, any>, debouncer),
);

const persistor = persistStore(store);

export { store, persistor };
