/* eslint-disable max-len */
import { handleActions, Action } from 'redux-actions';
import { IAccountData } from '@app/types/IAccountData';
import { IPaginatedData, GetUserProperties } from '@app/types/IPaginatedData';
import { RequestDataInterface } from '@app/types/requestDataInterface';
import {
  clearUserStateAction,
  deleteStudioInviteAction,
  disableAccountAction,
  editReportAccountStateAction,
  editReportPostStateAction,
  enableAccountAction,
  getAccountReportsAction,
  getCreatorsAction,
  getPostReportsAction,
  getStudiosAction,
  saveReportedUserData,
} from './UserActionConfig';

type PaginatedArtists = IPaginatedData<GetUserProperties<'artists'>>;
type PaginatedStudios = IPaginatedData<GetUserProperties<'studios'>>;

interface UserState {
  artists: PaginatedArtists | null;
  studios: PaginatedStudios | null;
  report: {
    account: any;
    post:any;
  }
  reportData: any;
}

const reportStatusTemplate = {
  open: {
    data: [],
    total: 0,
    hasMore: true,
  },
  ignored: {
    data: [],
    total: 0,
    hasMore: true,
  },
  restored: {
    data: [],
    total: 0,
    hasMore: true,
  },
  removed: {
    data: [],
    total: 0,
    hasMore: true,
  },
};

const defaultState: UserState = {
  artists: null,
  studios: null,
  report: {
    account: { ...reportStatusTemplate },
    post: { ...reportStatusTemplate },
  },
  reportData: null,
};

const userReducer = handleActions<UserState, any>(
  {
    [getCreatorsAction.toString()]: (
      state: UserState,
      { payload }: Action<PaginatedArtists>,
    ) => ({
      ...state,
      artists: payload,
    }),
    [getStudiosAction.toString()]: (
      state: UserState,
      { payload }: Action<PaginatedStudios>,
    ) => ({
      ...state,
      studios: payload,
    }),
    [disableAccountAction.toString()]: (
      state: UserState,
      { payload }: Action<{ id: string; status: IAccountData['status']; type: string }>,
    ) => {
      const { id, type } = payload;
      const key = type === 'studio' ? 'studios' : 'artists';

      return {
        ...state,
        [key]: state[key] ? {
          ...state[key],
          data: state[key]?.data.map((item:any) => (item.id === id ? { ...item, status: 'disabled' } : item)),
        } : null,
      };
    },
    [enableAccountAction.toString()]: (
      state: UserState,
      { payload }: Action<{ id: string; status: IAccountData['status']; type: string }>,
    ) => {
      const { id, type } = payload;
      const key = type === 'studio' ? 'studios' : 'artists';

      return {
        ...state,
        [key]: state[key] ? {
          ...state[key],
          data: state[key]?.data.map((item: any) => {
            if (item.id === id) {
              if (item.status === 'for-deletion' && item.is_disabled === true) {
                return { ...item, status: 'for-deletion', is_disabled: false };
              }
              return { ...item, status: 'active' };
            }
            return item;
          }),
        } : null,
      };
    },
    [deleteStudioInviteAction.toString()]: (
      state: UserState,
      { payload }: Action<{ id: string; status: IAccountData['status']; type: string }>,
    ) => {
      const { id, type } = payload;
      const key = type === 'studio' ? 'studios' : 'artists';

      return {
        ...state,
        [key]: state[key] ? {
          ...state[key],
          data: state[key]?.data.filter((item:any) => item.id !== id),
        } : null,
      };
    },
    [getAccountReportsAction.toString()]: (
      state: UserState,
      { payload }: Action<RequestDataInterface>,
    ) => {
      const {
        data: payloadData,
        meta: { current_page: page, total, last_page: lastPage },
      }:any = payload;

      if (payloadData?.length < 1) {
        const { links } = payload;

        const url = new URL(links.first);
        const status:any = url.searchParams.get('status');

        return {
          ...state,
          report: {
            ...state.report,
            account: {
              ...state.report.account,
              [status]: {
                data: page === 1 ? payloadData : [ ...(state.report.account[status] || []).data, ...payloadData ],
                total,
                hasMore: page < lastPage,
              },
            },
          },
        };
      }

      const status:any = payloadData[0]?.status;

      return {
        ...state,
        report: {
          ...state.report,
          account: {
            ...state.report.account,
            [status]: {
              data: page === 1 ? payloadData : [ ...(state.report.account[status] || []).data, ...payloadData ],
              total,
              hasMore: page < lastPage,
            },
          },
        },
      };
    },

    [editReportAccountStateAction.toString()]: (
      state: UserState,
      { payload }: any,
    ) => {
      const { report } = state;
      const { account }:any = report;

      const previousStatus = payload?.status;
      const newStatus = payload?.newStatus;

      const filteredPreviousStatus = account[previousStatus].data.filter(
        (item: any) => item.reported.id !== payload?.reported?.id,
      );

      const isDisabled = () => {
        if (previousStatus === 'removed' && newStatus === 'restored') {
          return false;
        }

        if (newStatus === 'removed') {
          return true;
        }

        return payload?.is_disabled;
      };

      const updatedAccount = {
        ...account,
        [previousStatus]: {
          data: filteredPreviousStatus,
          total: account[previousStatus].total - 1,
        },
        [newStatus]: {
          data: [
            {
              ...payload,
              status: newStatus,
              newStatus: null,
              is_disabled: isDisabled(),
            }, ...(account[newStatus].data) ],
          total: account[newStatus].total + 1,

        },
      };

      return {
        ...state,
        report: {
          ...report,
          account: updatedAccount,
        },
      };
    },

    [editReportPostStateAction.toString()]: (
      state: UserState,
      { payload }: any,
    ) => {
      const { report } = state;
      const { post }:any = report;

      const previousStatus = payload?.status;
      const newStatus = payload?.newStatus;

      const filteredPreviousStatus = post[previousStatus].data.filter(
        (item: any) => item.reported_id !== payload?.reported_id,
      );

      const isPostDisabled = () => {
        if (previousStatus === 'removed' && newStatus === 'restored') {
          return false;
        }

        if (newStatus === 'removed') {
          return true;
        }

        return payload?.is_post_disabled;
      };

      const updatedPost = {
        ...post,
        [previousStatus]: {
          data: filteredPreviousStatus,
          total: post[previousStatus].total - 1,
        },
        [newStatus]: {
          data: [
            {
              ...payload,
              status: newStatus,
              newStatus: null,
              is_post_disabled: isPostDisabled(),
            }, ...(post[newStatus].data) ],
          total: post[newStatus].total + 1,

        },
      };

      return {
        ...state,
        report: {
          ...report,
          post: updatedPost,
        },
      };
    },

    [getPostReportsAction.toString()]: (
      state: UserState,
      { payload }: Action<RequestDataInterface>,
    ) => {
      const {
        data: payloadData,
        meta: { current_page: page, total, last_page: lastPage },
      }:any = payload;

      if (payloadData?.length < 1) {
        const { links } = payload;

        const url = new URL(links.first);
        const status:any = url.searchParams.get('status');

        return {
          ...state,
          report: {
            ...state.report,
            post: {
              ...state.report.post,
              [status]: {
                data: page === 1 ? payloadData : [ ...(state.report.post[status] || []).data, ...payloadData ],
                total,
                hasMore: page < lastPage,
              },
            },
          },
        };
      }

      const status:any = payloadData[0]?.status;

      return {
        ...state,
        report: {
          ...state.report,
          post: {
            ...state.report.post,
            [status]: {
              data: page === 1 ? payloadData : [ ...(state.report.post[status] || []).data, ...payloadData ],
              total,
              hasMore: page < lastPage,
            },
          },
        },
      };
    },

    [saveReportedUserData.toString()]: (
      state: UserState,
      { payload }: Action<{ data: any, type: string }>,
    ) => ({
      ...state,
      reportData: {
        data: payload.data,
        type: payload.type,
      },
    }),
    [clearUserStateAction.toString()]: () => defaultState,
  },
  defaultState,
);

export default userReducer;
