import { AnyAction } from 'redux';
import { handleActions, Action } from 'redux-actions';

import {
  setThemeAction,
  setDarkModeModeAction,
  setDirAction,
  setLanguageAction,
  setCardShadowAction,
  toggleSidebarAction,
  hoverSidebarAction,
  toggleMobileSidebarAction,
  toggleLayoutAction,
  toggleHorizontalAction,
  setBorderRadiusAction,
} from './ThemeActionConfig';

interface ThemeState {
  activeDir?: string | any;
  activeMode?: string; // This can be light or dark
  activeTheme?: string; // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
  SidebarWidth?: number;
  MiniSidebarWidth?: number;
  TopbarHeight?: number;
  isCollapse?: boolean;
  isLayout?: string;
  isSidebarHover?: boolean | any;
  isMobileSidebar?: boolean;
  isHorizontal?: boolean;
  isLanguage?: string;
  isCardShadow?: boolean;
  borderRadius?: number | any;
}

const defaultState: ThemeState = {
  activeDir: 'ltr',
  activeMode: 'light', // This can be light or dark
  activeTheme: 'BLUE_THEME', // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
  SidebarWidth: 270,
  MiniSidebarWidth: 87,
  TopbarHeight: 70,
  isLayout: 'boxed', // This can be full or boxed
  isCollapse: false, // to make sidebar Mini by default
  isSidebarHover: false,
  isMobileSidebar: false,
  isHorizontal: false,
  isLanguage: 'en',
  isCardShadow: true,
  borderRadius: 7,
};

const themeReducer = handleActions<ThemeState, AnyAction>(
  {

    [setThemeAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      activeTheme: payload.activeTheme,
    }),

    [setDarkModeModeAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      activeMode: payload.activeMode,
    }),

    [setDirAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      activeDir: payload.activeDir,
    }),

    [setLanguageAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      isLanguage: payload.isLanguage,
    }),

    [setCardShadowAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      isCardShadow: payload.isCardShadow,
    }),

    [toggleSidebarAction.toString()]: (state: ThemeState) => ({
      ...state,
      isCollapse: !state.isCollapse,
    }),

    [hoverSidebarAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      isSidebarHover: payload,
    }),

    [toggleMobileSidebarAction.toString()]: (state: ThemeState) => ({
      ...state,
      isMobileSidebar: !state.isMobileSidebar,
    }),

    [toggleLayoutAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      isLayout: payload.isLayout,
    }),

    [toggleHorizontalAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      isHorizontal: payload.isHorizontal,
    }),

    [setBorderRadiusAction.toString()]: (state: ThemeState, { payload }: Action<AnyAction>) => ({
      ...state,
      borderRadius: payload.borderRadius,
    }),

  },
  defaultState,
);

export default themeReducer;
