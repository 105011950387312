/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';
// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const setThemeAction = createAction('APP_SET_THEME');
export const setDarkModeModeAction = createAction('APP_SET_DARK_MODE');
export const setDirAction = createAction('APP_SET_DIR');
export const setLanguageAction = createAction('APP_SET_LANGUAGE');
export const setCardShadowAction = createAction('APP_SET_CARD_SHADOW');
export const toggleSidebarAction = createAction('APP_TOGGLE_SIDEBAR');
export const hoverSidebarAction = createAction('APP_HOVER_SIDEBAR');
export const toggleMobileSidebarAction = createAction('APP_TOGGLE_MOBILE_SIDEBAR');
export const toggleLayoutAction = createAction('APP_TOGGLE_LAYOUT');
export const toggleHorizontalAction = createAction('APP_TOGGLE_HORIZONTAL');
export const setBorderRadiusAction = createAction('APP_BORDER_RADIUS');
