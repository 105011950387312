import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

// import { reducer as toastrReducer } from 'react-redux-toastr';

import persistConfig from '@config/redux-persist';
import AuthReducer from './auth/AuthReducer';
import AppReducer from './app/AppReducer';
import ThemeReducer from './theme/ThemeReducer';
import settingsReducer from './settings/SettingsReducer';
import UserReducer from './user/UserReducer';
import AdminReducer from './admin/AdminReducer';

const reducers = combineReducers({
  Auth: persistReducer(persistConfig.auth, AuthReducer),
  App: AppReducer,
  Theme: ThemeReducer,
  Settings: settingsReducer,
  User: UserReducer,
  Admin: AdminReducer,

  // toastr: toastrReducer,
});

export type IRootState = ReturnType<typeof reducers>;

export default reducers;
